* {
  box-sizing: border-box;
}

footer {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  height: 0px;
}

svg {
  display: block;
  border-radius: 50%;
  width: 100%;
  height: 100%; 
}

path {
  transition: all 0.1s;
}

/* .button__init {
  display: inline-block;
  background-color: #ff9800;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
} */
/* .button__init::after {
  content: "\f077";
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  font-size: 2em;
  line-height: 50px;
  color: #fff;
}
.btn__scroll:hover {
  cursor: pointer;
  background-color: #333;
}
#btn__scroll:active {
  background-color: #555;
} */
.btn__scroll {
  visibility: visible;
  position: fixed;
  /* left: 50%; */
  bottom: 80px;
  right: 30px;
  height: 0px;
  font-size: 3rem;
  background-color: blue !important;
  color: white;
  border-radius: 50%;
}

@media (min-width: 426px) {
  .btn__scroll {
    visibility: visible;
    position: fixed;
    /* left: 50%; */
    bottom: 80px;
    right: 100px;
    height: 0px;
    font-size: 10px;
    background-color: #36424a !important;
    border: 4px solid #36424a(0, 0, 0);
    box-shadow: 20px #36424a;
    color: white;
    border-radius: 50%;
    padding-top: 0px;
    padding-bottom: 40px;
    align-items: center;
    cursor: pointer;
  }
  
}