@media(max-width:425px) {
  
  .header__links {
    display: flex !important;
    flex-direction: row !important;
    width: 100% !important;
    height: 50px !important;
    justify-content: space-between !important;
    align-items: center !important;
    position: absolute !important;
    background-color: white !important;
  }

.logoooo {
  width: auto !important;
}

  .link__logo {
    text-decoration: none !important;
    color: white;
    object-fit: contain !important;
    width: 100px !important;
    margin-left: 10px;
  }

nav {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}

  .linkk {
    font-size: 12px !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-weight: bold !important;
    margin-right: 10px;
    text-decoration: none !important;
    color: grey !important;
    text-decoration-line: none !important;
    padding: 0px 5px 0px 20px;
    margin: 0px;
    margin-bottom: none !important;
    margin-left: 20px;
    margin-right: 80px;
    padding: 15px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    width: 100% !important;
  }

  .first__link {
    margin-top: 40px;
  }

  .selectedLink2 {
    width: 100vw !important;
  }

  .header__span {
    display: none;
  }

  .header__nav2 {
    display: none;
  }

  .header__nav__stack {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: white;
    width: 200px;
    z-index: 16;
    color: black;
    display: flex !important;
    flex-direction: column !important;
    text-align: left;
    align-items: flex-start !important;
  }

  .header__span {
    display: none;
  }

  .open__menu {
    cursor: pointer;
    margin-right: 20px;
  }



.close__menu {
  justify-self: right;
  position: fixed;
  left: 200 !important;
  top: 0 !important;
  z-index: 32;
  margin: 20px;
  margin-bottom: 10px;
}

.menu__toggle {
  transform: translateX(100%) !important;
  transition: transform 0.5s ease-in-out;
}
  

}

  @media(min-width:426px) {
    .header__links {
      display: flex;
      position: fixed;
      z-index: 1000;
      flex-direction: row;
      justify-content: space-between;
      justify-items: center;
      align-items: center !important;
      width: 100%;
      margin-right: 5% !important;
      align-items: center !important;
      background-color: white;
    /* margin: 0px 120px 0px 120px !important; */
    width: 100vw;
    height: 60px;
    padding: 0px 120px !important;
    box-shadow: 0px 20px 15px -20px rgb(160 160 160);
      
    }


    .header__nav2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      text-align: left;
      align-items: center !important;
    }

  
    .open__menu {
     display: none;
    }

    .close__menu {
      display: none;
    }

    .link__logo {
      text-decoration: none !important;
      color: black;
      object-fit: contain !important;
      width: 140px !important;
    }

    .linkk {
      font-size: 16px !important;
      font-family: Georgia, 'Times New Roman', Times, serif;
      font-weight: bold;
      margin: 0px 20px !important;
      padding-bottom: 5px;
      text-decoration: none !important;
      color: black !important;
    }
  }