.service__section {
 background-color: #F5F6F7;
 margin-bottom: 0px;
}

.service__main {
    display: flex !important;
    flex-direction: column !important;
    height: auto !important;
    margin-left :0px;
    margin-right :0px;
    margin-top: 0px !important;
    place-items: center;
    background-color: #F5F6F7 !important;
  }
  
  section {
    display: flex;
    flex-direction: column;
    margin-top: auto !important;
    background-color: #F5F6F7 !important;
  }


  
  
  h3 {
    color: black !important;
    font-size: 26px !important;
    font-weight: bold !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center !important;
  }
  h2{
    color: rgb(44, 40, 40) !important;
    font-family: 'Sofia Sans', sans-serif !important;
    font-weight: bold !important;
    font-size: 16px !important;
  }

  .breif__service__details {
    padding: 0px 0px !important;
  }
  
  p {
    color: grey !important;
    font-family: 'Sofia Sans', sans-serif !important;
    font-size: 14px !important;
  }
  .service__header {
    position: relative;
    height: 3%;
    color: #2E51A3 !important;
  }


   /* {
    background-color: #F5F6F7;
  } */
  



  @media (max-width: 768px) {

  .service__inner {
    display: block;
    width: 1000px !important;
    margin-top: 100px !important;
  }

  .services-each {
    display: flex;
    flex-direction: column-reverse !important;
    width: auto !important;
    border-radius: 20px !important;
    justify-content: center !important;
    align-items: center;
    margin: 30px 0px !important;
  }

  .service-page-each-img {
    background-color: white !important;
    padding: 20px !important;
    width: 94% !important;
    height: auto;
    border: 4px solid rgba(7, 26, 39, 0.9)
  }

  .service-each-text {
    width: 94% !important;
    padding: 40px 40px;
    background-image: radial-gradient(circle farthest-corner at 50% 50%, transparent, rgba(7, 26, 39, 0.9)), linear-gradient(180deg, rgba(24, 17, 116, 0.8), rgba(0, 0, 0, 0.8)), url("core-image-2.jpg");
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    text-align: left;

  }

  .service-title {
    font-size: 30px !important;
    color: white !important;
    text-align: left !important;
  }

  .breif__service__details {
    color: white !important;
  }

  .service-each-btn {
    background-color: #36424a !important;
    border-color: white !important;
    border-radius: 0px !important;
  }
}


  @media (max-width: 425px) {
    h3 {
      color: black !important;
      font-size: 22px !important;
      font-weight: bold !important;
      display: flex;
      flex-direction: row;
      justify-content: center !important;
      align-items: center !important;
      text-align: center !important;
    }
  }
  @media (min-width: 426px) {

    .service__main {
      margin-top: 60px !important;
    }

    .modal {
      z-index: 2000 !important;
      overflow-y: scroll !important;
      scrollbar-width: 20px !important;
      overflow-x: hidden !important;
      width: 100% !important;
    }

    .modal-dialog {
      margin: 0px !important;
      /* background-color: white; */
      display: flex;
      flex-direction: row !important;
      width: 100% !important;
      height: auto !important;
      min-height: 100vh;
      margin: 0px 0px !important;
    }
    .modal-header {
      width: 100vw !important;
      height: auto !important;
    }
    .modal-footer {
      width: 100% !important;
      height: auto !important;
    }
  
    section {
      border-top: 4px blue !important;
      padding: 0px 100px !important;
  
    }
  
    .service__inner {
      display: block;
      width: 1000px !important;
      margin-top: 100px !important;
    }

    .service-service-page {
      margin-top: -100px !important;
      margin-bottom: 60px!important;
    }

    .services-each {
      display: flex;
      flex-direction: row !important;
      width: 1000px !important;
      border-radius: 20px !important;
      justify-content: center !important;
      align-items: center;
      margin-bottom: 60px;
    }

    .service-page-each-img {
      background-color: white !important;
      padding: 20px 60px !important;
      width: 50% !important;
      height: 400px;
      border-bottom: 1px solid #150b38  !important;
    }

    .service-each-text {
      width: 35% !important;
      padding: 0px 50px;
      background-image: radial-gradient(circle farthest-corner at 50% 50%, transparent, rgba(7, 26, 39, 0.9)), linear-gradient(180deg, rgba(24, 17, 116, 0.8), rgba(0, 0, 0, 0.8)), url("core-image-2.jpg");
      height: 400px;
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: center;
      text-align: left;

    }

    .service-title {
      font-size: 22px !important;
      color: white !important;
      text-align: left !important;
    }

    .breif__service__details {
      color: white !important;
      line-height: 32px;
      font-family: 'Sofia Sans', sans-serif !important;
    }

    .service-each-btn {
      margin-top: 10px !important;
      background-color: #36424a !important;
      border-color: white !important;
      border-radius: 0px !important;
    }
  
    .service__card {
      border-radius:  20px 20px 20px 20px !important;
      box-shadow: 2px black !important;
      margin: 0px 20px !important;
      flex-basis: 33%;
      background-color: white;
    }

    p {
      color: rgb(71, 71, 71) !important;
      font-family: 'Sofia Sans', sans-serif !important;
      font-size: 16px !important;
    }
  
    /* .card-S {
      width: auto !important;
      margin-top: 10% !important;
      border-radius: 20px 20px 20px 20px !important;
      /* background-color: white !important; */
      /* flex-basis: 33%; */
    /* } */
  
    .service__inner:hover {
      border: 2px black;
    }
  
    
  }
  
  
  
  
  
  .modal-dialog {
    margin: 0px !important;
  }
  
  .modal-header {
    position: relative;
    left: 0px;
    width: 100%;
  }
  
  .fade {
    margin: 0px 0px 0px 0px !important;
    left: 0px;
  }
  
  .example-custom-modal-styling-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 100vw;
    height: auto;
    min-height: 100vh;
    margin: 0px 0px 0px 0px !important;
    background-color: white !important;
  }
  
  .modal__p {
    font-size: 14px;
    margin: 20px 0px;
    padding: 40px 40px 0px 40px;
  }
  
  .service__inner {
    display: flex;
    flex-direction: column;
    height: auto;
    border-radius: 20px !important;
    width: auto !important;
    
  }
  
  .service__inner::-webkit-scrollbar {
    display: none;
  }
  
  .service__inner {
    -ms-overflow-style: none !important;
    scrollbar-width: none;
  }
  
  .service__card {
    padding: 20px 5px !important;
    justify-content: center;
    border-radius:  20px 20px 20px 20px !important;
    box-shadow: 1 1 2px #9f9f9f !important;
    background-color: white;
     margin: 10px 40px 10px 40px !important;
  }
  
  .card-S:hover {
    border: 2px black;
  }
  
  .card-S {
    width: auto !important;
    margin-top: 10% !important;
    border-radius: 20px 20px 20px 20px !important;
  
    /* background-color: white !important; */
    /* align-content: center;
    justify-content: center;
    align-content: center !important;
    align-items: center; */
  }
  
  .img {
    object-fit: contain !important;
    height: 150px;
    border-radius: 5px 5px 0px 0px !important;
  }
  
  
  img {
    object-fit: contain !important;
    width: 200px !important;
  }
  
  .service__card > .MuiSvgIcon-root {
    margin-left: auto;
    margin-right: auto;
    color: grey !important;
  }

  