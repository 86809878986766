.contact__section {
  padding: none ;
  margin: none;
}

.contact__main {
  background-color: white;
  margin-top: 40px !important;
}

.contact__link {
  text-decoration: none;
  cursor: pointer;
  color: #3f51b5;
}


@media  (max-width:425px) {
  
  .contact__main {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: space-around;
  }

  .contact__description {
    margin: 10px !important;
    border-bottom: 0px  solid white !important;
  }

  .contact__h2 {
    font-size: 12px !important;
    color: black !important;
    /* jj */
  }


  .contact__us {
    margin: 10px 0px !important;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

 
  
  .card {
    display: flex;
    border: none !important;
    flex-direction: column !important;
    background-color: white !important;
    color: black !important;
    justify-content: left !important;
    text-align: left !important;
    padding: 20px 20px;
    padding-bottom: 20px;
    
    object-fit: cover !important;
    background-repeat: no-repeat !important;
    background-size: auto, auto, cover !important;
    background-image: radial-gradient(circle farthest-corner at 50% 50%, transparent, rgba(7, 26, 39, 0.9)), linear-gradient(180deg, rgba(24, 17, 116, 0.8), rgba(0, 0, 0, 0.8)), url("core-image-2.jpg");
    background-position: 0px 0px, 0px 0px, 40% 80%;


  }


  .contact__card1, .contact__card2, .contact__card3 {
    color: black !important;
    font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
    align-items: baseline !important;
    text-align: left !important;
    justify-content: left;
    margin: 10px;
    padding: 10px;
  }


  .clients__header {
    margin: 0px !important;
    color: white !important;
    font-weight: bold;
    text-align: left;
    font-size: 1rem;
    /* margin-bottom: 20px !important; */
  }

  .partners__txt {
    color: #fff;
    font-size: 14px !important;
    font-weight: 500 !important;
    background-color: transparent;
    text-decoration: none;
    height: auto;
    justify-content: center;
    padding: 0px !important;
    margin-top: 10px !important;
    line-height: 1.4
  }

  .conta
  
  .copyright {
    display: flex;
    flex-direction: column;
    background-color: #36424a !important;
    color: white !important;
    min-height: 60px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

 
}




@media (min-width:426px) {
  .contact__main {
    display: flex;
    flex-direction: row;
    justify-content: center !important;
  }

  .contact__us {
    margin: 10px 0px !important;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 1200%;
  }

  .contact__description {
    margin: 10px !important;
   
  }
  .footer__image {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .contact__h2 {
    margin-top: 22px !important;
    color: black !important;
    font-size: 18px !important;
    padding-top: 30px !important;
  }

  
  .card {
  display: flex !important;
  justify-content: left !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
border: none !important;
 background-color: white !important;
  padding: 20px 0px !important;
  padding-bottom: 20px !important;
  margin: auto auto;

}

.contact__card1 {
  flex-basis: 40% !important;
  color: black !important;
  font-size: 16px;
  font-weight: 400 !important;
  font-stretch: wider !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 10px;
  text-align: left;
  padding: 20px 40px;
  padding-left: 40px;
  border: 3px solid white !important;
}


.contact__card2 {
  flex-basis: 26% !important;
  color: black !important;
  font-size: 16px;
  font-weight: 400 !important;
  font-stretch: wider !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 10px;
  text-align: left;
  padding: 20px 40px;
  border: 3px solid white !important;

}

.contact__card22 {
  margin-left: 10px !important;
  padding-left: 40px !important;
}

.clients__header {
  margin: 0px !important;
  color: white !important;
  font-weight: bold;
  font-size: 20px !important;
  margin-bottom: 20px !important;
}

.partners__txt {
  color: white;
  font-size: 16px !important;
  font-weight: 350 !important;
  text-decoration: none;
  height: auto;
  justify-content: center;
  padding: 0px !important;
  margin-top: 0px !important;
  line-height: 2

}

.copyright {
  background-color: #36424a !important;
  color: white !important;
  padding: 20px 0px 20px 0px;
  
}

.card {
  object-fit: cover !important;
  background-repeat: no-repeat !important;
  background-size: auto, auto, cover !important;
  background-image: radial-gradient(circle farthest-corner at 50% 50%, transparent, rgba(7, 26, 39, 0.9)), linear-gradient(180deg, rgba(24, 17, 116, 0.8), rgba(0, 0, 0, 0.8)), url("core-image-2.jpg");
  background-position: 0px 0px, 0px 0px, 40% 80%;

}

.cont-card {
  display: flex;
  flex-direction: column !important;
  margin-top: 60px;
}

.flex-1, .flex-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
}
