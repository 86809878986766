.about__main {
    background-color: #2e51a3;
    padding-bottom: -10px;

}

.about__description {
    margin: 55px 0px 10px 0px;
    padding-top: 20px;
    color: #000 !important;
    font-size: 18px !important;
    font-weight: bold;
}

.about__overview {
    background-color: #F4F7FE  !important;
    height: auto;
    padding: 40px;
    margin: 20px;
}

.about__txt {
    color: #000  !important;
    margin: 10px 70px !important;
}

p{ 
    color: #000 !important;
    font-size: 14px !important;
    font-family: sans-serif;
}
