

a.active {
  padding: none !important;
}

@media (min-width: 0px) and (max-width: 426px) {
  a.active {
    padding: none !important;
  }

  .carousel-inner {
    height: 100vh;
    background-color: black
  }


  .top__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    height: 60px;
    position: fixed !important;
    top: 0px !important;
    z-index: 1000;
    width: 100% !important;
  }


  .carousel-item-margin {
    margin-top: 70px !important;
}

  .header__nav {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: white;
    width: 200px;
    z-index: 16;
    color: black;
    display: flex !important;
    flex-direction: column !important;
    text-align: left;
    align-items: flex-start !important;
    box-shadow: 0px 15px 10px -15px rgb(206, 206, 206);    

  }

  .header__span {
    display: none;
  }

 

  .open__menu {
    cursor: pointer;
    margin-right: 10px;
  }



.close__menuu {
  justify-self: right;
  position: fixed;
  right: 0 !important;
  top: 0 !important;
  z-index: 32;
  margin: 20px;
}

.menu__toggle {
  transform: translateX(100%) !important;
  transition: transform 0.5s ease-in-out;
}
  
  .carousel-item {
    margin-top: 0px !important;
    transition: transform 0.1s ease-in-out, opacity 0.9s ease-out;
    /* background-color: black; */
    background-image: linear-gradient(
      to bottom left,
      rgb(0, 0, 0),
      black,
      black,
      black,
      #2e51a3
    );
    /*background: #ec9393;  fallback colour. Make sure this is just one solid colour. */
    /*background: -webkit-linear-gradient(rgba(243, 61, 61, 0.8), rgba(195, 55, 100, 0.8));
      background: linear-gradient(rgba(243, 61, 61, 0.8), rgba(195, 55, 100, 0.8)); /*The least supported option. */
  }
  
  .banner__img {
    object-fit: cover;
    height: 100vh !important;
    width: 100vw !important;
    opacity: 0.9 !important;
    border-radius: 0px 0px 0px 0px;
  }
  
  .logo {
    color: white;
    object-fit: contain !important;
    width: auto !important;
    height: auto;
    background-color: white;
    width: 160px;
    z-index: 200 !important;
    margin-bottom: 0px;
  }









  .banner__txt {
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
  }

  .banner__header__links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center !important;
    align-content: center !important;
    align-content: flex-start !important;
    position: relative;
    top: auto !important;
    left: auto !important;
    margin-right: 40px;
    padding: 0px 0px;
  }

  .carousel-caption {
    top: 180px !important;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    font-size: 30px;
    left: 0% !important;
    right: 0% !important;
    bottom: 0rem !important;
    padding-top: 0rem !important;
    margin: 0rem !important;
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }

  /* .header__links {
    display: flex;
    flex-direction: row;
    width: 100vw;
    justify-content: space-around;
    position: absolute;
    left: 0px;
    top: 0px !important;
    margin-top: 4px;
  } */

  .logoooo {
    position: relative;
    left: 0px !important;
    margin-right: auto !important;
  }
  .logo {
    text-decoration: none;
    color: white;
    object-fit: contain !important;
    width: 100% !important;
    height: 100%;
    width: 200px;
    height: 60px ;
    justify-content: center;
    margin-left: 10px;
    z-index: 10000 !important;
  }

  p.link {
    font-size: 12px !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-weight: bold !important;
    margin-right: 10px;
    text-decoration: none !important;
    color: grey !important;
    text-decoration-line: none !important;
    padding: 0px 5px 0px 20px;
    margin-top: 0px;
    margin-bottom: none !important;
    margin-left: 20px;
    margin-right: 20px !important;
    padding: 5px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    width: 100% !important;
  }


  .selectedLink {
    width: 100vw !important;

  }

  .home__icon__link {
    margin-top: 30px !important;
    font-family: 'Sofia Sans', sans-serif;

  }

  .banner__img {
    object-fit: cover !important;
    height: 100vh !important;
    width: 100vw !important;
  }

  p.description {
    font-size:2.2rem !important;
    font-weight: bolder !important;
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    text-shadow: 0 0 10px #2E51A3 !important;
    color: white !important;
  }

  .banner__btn {
    display: flex;
    flex-direction: column;
    max-width: 90vw;
    align-items: center;
    justify-content: center;
    align-self: center !important;
  }

  .MuiButton-root {
    max-width: 50vw !important;

  }

  .btn {
    background-color: #36424a !important;
    border-color: white !important;
    border-radius: 10px !important;

    padding: 10px 20px !important;
    background-color: #36424a !important;
    border: 3px solid white !important;
    border-radius: 10px !important;
    font-size: 1.1rem !important;
  }

  .carousel-caption > h3 {
    position: relative;
    /* top: 20px; */
    align-content: center !important;
    font-size: 50px !important;

    font-family: monospace;
  }






  /* .description {
    font-size: 35px;
    font-weight: bolder;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-shadow: 0 0 10px #2E51A3 !important;
  }
  
  .carousel-caption > h3 {
    position: relative;
    top: 20px;
    font-size: 60px;
    font-family: monospace;
  } */


  span.sr-only {
    display: none;
  }
  
  
  
  
  span.carousel-control-next-icon,
  span.carousel-control-prev-icon {
    display: none;
  }
}


@media (min-width: 0px) and (max-width: 1024px) {
  .values {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 2560px) {

.values {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0px;
  padding:  0px;
}

.focus {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 0px 8px 10px;
  color: #2B54A3 !important;
  font-size: 14px !important;
  font-weight: 800 !important;
}

.execution {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 0px 8px 10px;
  color: #EE3147 !important;
  font-size: 14px !important;
  font-weight: 800 !important;
}

.respect {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 0px 8px 10px;
  color: #FFD500 !important;
  font-size: 14px !important;
  font-weight: 800 !important;
}
}








@media (min-width: 426px) and (max-width: 5000px) {
  a.active {
    padding: none !important;
  }

  .carousel__inner {
    margin: 0px !important;
    padding: 0px !important;
  }

  .top__header{
    background-color: white !important;
    height: 70px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    position:fixed !important;
    z-index: 1000;
    width: 100vw !important;
  }

 
  
  .header__span {
    display: flex !important;
    font-family: 'Sofia Sans', sans-serif;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    align-content: center !important;
    margin-left: 60px;
    padding-top: 0px !important;
  
  }

  .header__p {
    margin: -10px 5px 0px 0px !important;
    font-family: 'Sofia Sans', sans-serif;
    color: #36424a !important;
    font-weight: 600 !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 12px !important;
    text-transform: uppercase;
  }

  
  .open__menu {
    display: none;
  }

  .close__menuu {
    display: none;
  }
  .header__nav {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin: 0px 0px 0px 0px !important;
    margin-right: 0px !important;
    bottom: -20px;
    z-index: 1;
    width: 100vw;
    height: 60px;
    padding-top: 10px !important;
    padding-left: 180px !important;
    padding-bottom: 20px;
    box-shadow: 0px 20px 15px -20px rgb(160, 160, 160);    


  }

  .carousel-item-margin {
    margin-top: 90px !important;
  }


  .carousel-caption {
      top: 300px !important;
      font-size: 30px;
      left: 0% !important;
      right: 0% !important;
      bottom: 0rem !important;
      padding-top: 0rem !important;
      padding-top: 0rem !important;
  }


  

  .banner__header__links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    align-items: center;
    top: auto !important;
    left: 0px !important;
    padding: 0px 30px;


  }


  img.logo {
    text-decoration: none;
    position: relative;
    color: white;
    object-fit: contain !important;
    width: 190px !important;
    /* height: auto; */
    margin-top: 0px !important;
    justify-content: center;
    margin-left: 120px;
    margin-bottom: 0px;
    height: 110px;
    background-color: white;
    padding: 40px 20px 10px 20px;
    width: 160px;
    z-index: 100000 !important;
    margin-bottom: 0px;
  }


  .first-selected-link {
    margin-left: 20px !important;
  }


  p.link {
    font-size: 16px !important;
    font-weight: bold !important;
    margin-left: 5px;
    margin-top: 10px;
    margin-right: 5px;
    text-decoration: none;
    color: #36424a !important;
    text-decoration-line: none;
    padding: 5px;
    margin-bottom: none !important;
  }

  p.home__icon__link {
    margin: 10px 5px 10px 5px !important;
    font-family: 'Sofia Sans', sans-serif;

  }
  .carousel-caption > a {
    font-size: 10px;
    color: black !important;
  }

  .banner__txt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .banner__img {
    object-fit: cover !important;
    height: 100vh !important;
    width: 100vw !important;
  }

  .carousel-caption > h3 {
    position: relative;
    /* top: 20px; */
    align-content: center !important;
    font-size: 50px !important;
    color: black !important;

    font-family: monospace;
  }
  
  
  p.description {
    font-size: 4rem !important;
    font-weight: bolder !important;
    font-family: 'Sofia Sans', sans-serif !important;
    text-shadow: 0 0 10px #2E51A3 !important;
    color: white !important;
  }


  .carousel-control-next, .carousel-control-prev {
    top: 30% !important;
    bottom: 30% !important;
    z-index: 1000 !important;
  
  }

  span.carousel-control-next-icon,
  span.carousel-control-prev-icon {
    height: 70px !important;
    width: 70px !important;
    font-weight: bolder !important;
    font-size: 100px !important;
    color: white !important;
  }

  span.sr-only {
    display: none;
  }

  .btn {
    margin: 5px !important;
    padding: 10px 50px !important;
    background-color: #36424a !important;
    border: 3px solid white !important;
    border-radius: 0px !important;  }

  .MuiButtonBase-root.MuiButton-root:hover {
    zoom: 100%;
    color: white;
    padding: 10px;
  }
  .MuiButton-label:hover {
    color: white;
  }

  .the-nav-links {
    margin-left: 100px;
    display: flex;
    
  }
}






@media (min-width: 426px) and (max-width: 1440px) {


  img.logo {
    text-decoration: none;
    position: relative;
    color: white;
    object-fit: contain !important;
    width: 190px !important;
    /* height: auto; */
    margin-top: 0px !important;
    justify-content: center;
    margin-left: 40px;
    margin-bottom: 0px;
    height: 110px;
    background-color: white;
    padding: 40px 20px 10px 20px;
    width: 160px;
    z-index: 100000 !important;
    margin-bottom: 0px;
  }
  }







