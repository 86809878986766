html {
    overflow-x: hidden !important;
}


@media (min-width:701px) {

.about__section {
    background-color: white !important;
}
.about__main {
    background-color: white;
    padding: 0px 0px 0px 0px;
    padding-top: 60px;

}

.about__description {
    margin: 20px 0px 10px 70px;
    color: black !important;
    font-size: 26px !important;
    font-weight: 425;
}

.about__name {
    display: flex;
    flex-direction: row;
    background-color: #F4F7FE !important;
    justify-content: center !important;


}

.about__overview {
    width: 600px;
    background-color: #F4F7FE !important;
    height: auto;
    padding: 0px 0px 60px 0px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    
}

.about__txt {
    color: black !important;
    font-weight: 350;
    font-size: 18px !important;
    text-align: left;
}

.about__img {
    width: 40%;
    background-color: #F4F7FE !important;
    margin-top: 110px;
    margin-right: 0px;
    padding-bottom: 150px;
}

 img.imgg {
    object-fit: cover !important;
    width: 90% !important;
    height: auto !important;
} 

.image__about {
    object-fit: fill !important;
    background-color: white !important;
    width: 50% !important;
    margin: 60px 0px;
}

p{ 
    color: white !important;
    font-size: 14px !important;
    font-family: sans-serif;
}

.about__chunk {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    margin: 0px 0px 0px 0px;
    background-color: #F4F7FE !important;
    margin-top: 0px;

}

  h1.aboutt {
    color: white;
    font-size: 4rem;
    line-height: 2.5rem;
    max-width: 400px;
    font-family: 'Sofia Sans', sans-serif !important;
    text-shadow: 0 0 10px #2E51A3 !important;

  
  }


  

.each {
    width: 40%;
    margin-top: 20px;
    margin-bottom: 40px;
    justify-content: center;
    padding: 20px 40px;
    border-radius: 10px !important;
    background-color: white !important;
    background-image: radial-gradient(circle farthest-corner at 50% 50%, transparent, rgba(7, 26, 39, 0.9)), linear-gradient(180deg, rgba(24, 17, 116, 0.8), rgba(0, 0, 0, 0.8)), url("core-image-2.jpg");
}

.each__header {
    color: white;
    margin-top: 20px;
}

.each__content {
    color: white !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    padding: 20px 60px;
}

.values__span {
    font-weight: 900;
}


.about-page-div-header {
    display: none !important;
    width: 100% !important;
    height: 400px !important;
    object-fit: cover !important;
    background-repeat: no-repeat !important;
    background-size: auto, auto, cover !important;
    background-image: radial-gradient(circle farthest-corner at 50% 50%, transparent, rgba(20, 49, 74, 0.4)), linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("about-img.png");
    background-position: 0px 0px, 0px 0px, 40% 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

}


@media (max-width:425px) {
    .about__section {
    background-color: white !important;
}


    .about__overview {
        padding: 0px;
    }


    .about__main {
        background-color: #F4F7FE ;
    }

    p.about__txt {
        font-size: 14px !important;
        font-weight: 300;
        margin: 10px 20px !important;
    }
.about__img {
    background-color: white !important;
    padding: 20px 0px 10px 0px;
    position: relative;
    bottom: -10px;
}

    .image__about {
        object-fit: fill !important;
        width: 100% !important;
        background-color: white !important;
    }

    img {
        object-fit: cover;
        width: 100vw;
    }

    .about__chunk {
        margin: 0px 0px 30px 0px;
        padding-top: 20px;
        background-color: white !important;
        margin-bottom: 20px;
    }

    .each {
        margin-top: 60px;
        border-radius: 0px 0px 10px 10px !important;
        background-color: rgba(24, 17, 116, 0.8) !important;
        margin: 0px 40px;
        border-radius: 10px !important;
        background-color: white !important;
        background-image: radial-gradient(circle farthest-corner at 50% 50%, transparent, rgba(7, 26, 39, 0.9)), linear-gradient(180deg, rgba(24, 17, 116, 0.8), rgba(0, 0, 0, 0.8)), url("core-image-2.jpg");
    }

    .about__name {
        display: flex;
        flex-direction: column;
        flex-direction: column-reverse;
    }

    .imgg {
    object-fit: cover !important;
    width: 100% !important;
    height: auto !important;
    }

    .each__header {
        color: white;
        padding-top: 20px;

    }

    h4.each__header.first {
        margin-top: 0px !important;
        background-color: transparent;
    }

    .each__content {
        color: white !important;
        font-size: 14px !important;
        font-weight: 300 !important;
        padding: 0px 30px 30px 30px;
        margin-bottom: 20px !important;
    }

    .values__span {
        font-weight: 900;
    }

    .copyright__h6 {
        background-color: #36424a;
        color: white;
        height: 60px;
        align-items: center;
        margin-bottom: 0px !important;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

}