.service__main {
  display: grid;
  height: auto !important;
  margin-left :20px;
  margin-right :20px;
  place-items: center;
  background-color: #F5F6F7 !important;
  margin-top: auto;
}

section {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  background-color: #F5F6F7 !important;
}

h3 {
  color: grey !important;
  margin-top: 60px !important;
  font-size: 30px !important;
}

h2{
  color: rgb(44, 40, 40) !important;
  font-weight: bold !important;
  font-size: 22px !important;
  margin: 20px 0px !important;
}

.breif__service-details {
  padding: 0px 5px !important;
}

p {
  color: grey !important;
}
.service__header {
  position: relative;
  height: 3%;
  color: #2E51A3 !important;
}


@media (min-width: 767px) {
.service-individual {
  max-width: 1200px;
  display: flex;
  flex-direction: column !important;
}
}

@media (min-width: 768px) {
  .modal-dialog {
    margin: 0px !important;
    /* background-color: white; */
    display: flex;
    flex-direction: row !important;
    width: 100% !important;
    height: auto !important;
    min-height: 100vh;
    margin: 0px 0px !important;
  }
  .modal-header {
    width: 100vw !important;
    height: auto !important;
    background-color: white !important;
  }


  .modal-footer {
    width: 100% !important;
    height: auto !important;
  }

  section {
    border-top: 4px blue !important;
    padding: 0px 100px !important;

  }

  .service__inner {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    border-radius: 20px !important;
    width: auto !important;
    justify-content: center !important;
  }

  .service-individual {
    max-width: 1200px;
    display: flex;
    flex-direction: row !important;
  }

  .service__card {
    border-radius:  20px 20px 20px 20px !important;
    box-shadow: 0 0 1px #9f9f9f !important;
  }

  .service__card.aos-init.aos-animate {
    margin: 40px 40px !important;
    padding: 40px 20px !important;
  }

  .card-S {
    width: auto !important;
    margin-top: 10% !important;
    border-radius: 20px 20px 20px 20px !important;
    background-color: white !important;
    /* flex-basis: 33%; */
  }

  .service__inner:hover {
    border: 2px black;
  }

  
}





.modal-dialog {
  margin: 0px !important;
}

.modal-header {
  position: relative;
  left: 0px;
  width: 100% !important;
  background-color: white !important;
}

.fade {
  margin: 0px 0px 0px 0px !important;
  left: 0px;
}

.example-custom-modal-styling-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  width: 100vw;
  height: auto;
  min-height: 100vh;
  margin: 0px 0px 0px 0px !important;
  background-color: white !important;
}

.modal__p {
  font-size: 14px;
  margin: 20px 0px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  text-align: left;
}

.coreService {
  object-fit: cover !important;
  background-repeat: no-repeat !important;
  background-size: auto, auto, cover !important;
  background-image: radial-gradient(circle farthest-corner at 50% 50%, transparent, rgba(7, 26, 39, 0.9)), linear-gradient(180deg, rgba(24, 17, 116, 0.8), rgba(0, 0, 0, 0.8)), url("core-service-img.png");
  padding-left: 40px !important;
  color: white;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  justify-content: center;
  margin-left: 0px !important;
  margin-top: 40px !important;
}

.service-each-the-left {
display: flex !important;
flex-direction: column !important;
align-items: center !important;
justify-content: center !important;
}

.individual-service-image {
  position: relative;
  border: 3px solid #ffcb21 !important;
  margin-top: 40px !important;
  width: 80% !important;
  min-height: 160px !important;
  margin-left: 0px !important;
}

li {
  margin: 10px;
}

.service__inner {
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 20px !important;
  width: auto !important;
}


.services-each {
  display: flex;
  flex-direction: column;
}

.service__inner::-webkit-scrollbar {
  display: none;
}

.service__inner {
  -ms-overflow-style: none !important;
  scrollbar-width: none;
}

.service__card {
  padding: 60px 40px !important;
  justify-content: center;
  border-radius:  20px 20px 20px 20px !important;
  box-shadow: 0 0 20px #c7c7c7 !important;
   margin: 20px 20px 20px 20px !important;
}

.service__card.aos-init.aos-animate {
  padding: 40px 20px !important;
}

.card-S:hover {
  border: 2px black;
}

.card-S {
  width: auto !important;
  margin-top: 10% !important;
  border-radius: 20px 20px 20px 20px !important;

  background-color: #F5F6F7 !important;
  /* align-content: center;
  justify-content: center;
  align-content: center !important;
  align-items: center; */
}

.img {
  object-fit: contain !important;
  height: 150px;
  border-radius: 20px 20px 0px 0px !important;
}


img {
  object-fit: contain !important;
  width: 200px !important;
}

.service__card > .MuiSvgIcon-root {
  margin-left: auto;
  margin-right: auto;
  color: grey !important;
}




.individual-service-page {
  background-color: white;
}


.service-header-background {
  width: 100% !important;
  height: 400px !important;
  object-fit: cover !important;
  background-repeat: no-repeat !important;
  background-size: auto, auto, cover !important;
  background-image: radial-gradient(circle farthest-corner at 50% 50%, transparent, rgba(20, 49, 74, 0.4)), linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("core-image-2.jpg");
  background-position: 0px 0px, 0px 0px, 40% 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



h1.service {
  color: white;
  font-size: 2rem;
  line-height: 2.5rem;
  max-width: 300px;
  font-family: 'Sofia Sans', sans-serif !important;
  text-shadow: 0 0 10px #2E51A3 !important;


}

@media (min-width: 768px) {
.service-individual {
  max-width: 1200px;
  display: flex;
  flex-direction: row !important;
  padding: -180px 100px 0px 100px !important;
  margin: 60px 0px 0px 100px !important;
  background-color: white;
  align-items: top;
}


.coreService {
  object-fit: cover !important;
  background-repeat: no-repeat !important;
  background-size: auto, auto, cover !important;
  background-image: radial-gradient(circle farthest-corner at 50% 50%, transparent, rgba(7, 26, 39, 0.9)), linear-gradient(180deg, rgba(24, 17, 116, 0.8), rgba(0, 0, 0, 0.8)), url("core-service-img.png");
  padding: 60px 20px 20px 20px;
  padding-left: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start !important;
  align-items: left;
  text-align: left;
  justify-content: center;
  margin-left: 40px !important;
}

.service-each-the-left {
  width: 50%;
  padding-top: 5px !important;
  margin-right: 100px;
}

.service-each-the-right {
  width: 50%;
  margin-right: 100px;
  height: auto;
  align-items: top;
}

.slickline-service-right{
  margin-top: 0px !important;
}


.core-service-title {
  font: 900 !important;
  text-transform: uppercase !important;
}

.individual-service-image {
  position: relative;
  border: 5px solid #ffcb21 !important;
  margin-bottom: 60px !important;
  width: 85% !important;
  height: auto !important;
  margin-left: 40px !important;

}

.modal__img {
  position: relative;
  object-fit: cover !important;
  left: 30px !important;
  top: 30px !important;
  width: 100% !important;
  height: 100% !important;
  
}



.pressure-control-flex {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100% !important;
}


.pressure-control-img-flex {
  width:  30% !important;
}

.pressure-control-modal-flex {
  width:  40% !important;
  margin-left: 40px !important;
}

h1.service {
  color: white;
  font-size: 4rem;
  line-height: 4.5rem;
  font-family: 'Sofia Sans', sans-serif !important;
}

}